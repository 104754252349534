    .controller('loginCTRL', function ($scope, $state) {

    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            $scope.user = user;
            console.log(user);
            $state.go('sites', {reload: true});
        } else {

            var uiConfig = {
                signInFlow: 'popup',
                signInSuccessUrl: '/',
                signInOptions: [
                    // Leave the lines as is for the providers you want to offer your users.
                    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                    //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
                    //firebase.auth.GithubAuthProvider.PROVIDER_ID,
                    firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    //firebase.auth.PhoneAuthProvider.PROVIDER_ID
                ],
                // Terms of service url.
                tosUrl: 'www.dgoc.com'
            };


            var ui = new firebaseui.auth.AuthUI(firebase.auth());
            ui.start('#firebaseui-auth-container', uiConfig);
        }
    });


    $scope.resendEmail = function () {
        var user = firebase.auth().currentUser;

        user.sendEmailVerification().then(function () {
            document.getElementById('usertext').innerHTML = "Check inbox."
        }).catch(function (error) {
            // An error happened.
        });
    }
});