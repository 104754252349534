angular.module('app')
    .controller('fabCtrl', function ($scope, $mdDialog, $timeout, $state, routers) {
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            $scope.user = user;
            console.log(user);
        } else {
            $state.go("login");
        }
    });
    $scope.$state = $state;
    $scope.routers = routers;
    var self = this;

    self.hidden = false;
    self.isOpen = false;
    self.hover = false;
    $scope.$watch('fab.isOpen', function (isOpen) {
        if (isOpen) {
            $timeout(function () {
                $scope.tooltipVisible = self.isOpen;
            }, 600);
        } else {
            $scope.tooltipVisible = self.isOpen;
        }
    });

    $scope.newsite = function () {
        $mdDialog.show({
            controller: newsiteCtrl,
            templateUrl: 'partials/newsite.html',
            locals: {
                routers: $scope.routers
            }
        })
    };

    function newsiteCtrl($scope, $mdDialog, routers) {
        $scope.routers = routers;

        $scope.hide = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.updateEnd = function () {
            var endtime = document.getElementById("endtime").value;
            console.log(endtime);
            $scope.endtime = endtime;
            document.getElementById("endtime").value = endtime;
        };

        $scope.updateStart = function () {
            var starttime = document.getElementById("starttime").value;

            console.log(starttime);
            $scope.starttime = starttime;
            document.getElementById("dayStart").value = starttime;

        };

        $scope.submit = function () {
            var newKey = firebase.database().ref().child('sites').push().key;

            if ($scope.fileName) {
                var filename = $scope.fileName;
                var file = $scope.files[0];
                console.log(filename);
                console.log(file);
                var sitefile = firebase.storage().ref('/sites/' + newKey + '/' + filename);

                sitefile.put(file).then(function (snapshot) {
                    console.log('uploaded file to storage');
                    console.log('url is ' + snapshot.downloadURL);
                    var attachment = snapshot.downloadURL;
                    createsite(attachment, filename)
                })

            } else {
                var attachment = "";
                var filename = "";
                createsite(attachment, filename)
            }

            function createsite(attachment, filename) {

                console.log($scope.router);
                var name = $scope.name;
                var starttime = $scope.starttime;
                var endtime = $scope.endtime;
                if ($scope.location) {
                    var location = $scope.location;
                } else {
                    var location = "";
                }
                if ($scope.objective) {
                    var objective = $scope.objective;
                } else {
                    var objective = "";
                }
                if ($scope.router) {
                    var router = $scope.router;
                } else {
                    var router = "";
                }
                if ($scope.ce) {
                    var ce = $scope.ce;

                } else {
                    var ce = "";
                }

                console.log("Creating site " + name);

                var siteData = {
                    name: name,
                    starttime: starttime,
                    endtime: endtime,
                    location: location,
                    attachment: attachment,
                    filename: filename,
                    ce: ce,
                    router: router,
                    objective: objective
                };
                var updates = {};
                updates['/sites/' + newKey] = siteData;

                return firebase.database().ref().update(updates).then(function () {
                    $mdDialog.hide();
                });
            }
        };


    }


    $scope.newrouter = function (ev) {
        $mdDialog.show({
            controller: newrouterCtrl,
            templateUrl: 'partials/newrouter.html',
            targetsite: ev,
            onComplete: function () {
                var options = {
                    // Some common settings are 20, 50, and 100
                    quality: 100,
                    destinationType: navigator.camera.DestinationType.FILE_URI,
                    // In this app, dynamically set the picture source, Camera or photo gallery
                    sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
                    encodingType: navigator.camera.EncodingType.JPEG,
                    targetHeight: 100,
                    mediaType: navigator.camera.MediaType.PICTURE,
                    allowEdit: true,
                    correctOrientation: true  //Corrects Android orientation quirks
                };


                navigator.camera.getPicture(function cameraSuccess(imageURI) {
                    console.log(imageURI);
                    var image = "data:image/jpeg;base64," + imageURI;
                    console.log(image);
                    document.getElementById("placeholderImage").src = image;

                    // Do something with image

                }, function cameraError(error) {
                    console.debug("Unable to obtain picture: " + error, "app");

                }, options);
            }
        })
    };

    function newrouterCtrl($scope, $mdDialog) {


        $scope.hide = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };


        $scope.getPic = function () {
            document.querySelector('.cordova-camera-select').click();
        };


        $scope.submit = function () {
            var name = $scope.name;
            var title = $scope.title;
            var image = document.getElementById("placeholderImage").src;
            var bio = $scope.bio;
            var company = $scope.company;
            console.log("creating router " + name);

            var newrouter = firebase.database().ref('routers').push();

            if (title) {
                var title = title;
            } else {
                var title = "";
            }
            if (bio) {
                var bio = bio;
            } else {
                var bio = "";
            }
            if (image) {
                var image = image;
            } else {
                var image = "";
            }
            if (company) {
                var company = company;
            } else {
                var company = "";
            }
            return newrouter.set({
                name: name,
                title: title,
                company: company,
                image: image,
                bio: bio
            }).then(function () {
                $mdDialog.hide();
            });

        };
    }
});