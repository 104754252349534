    .controller('routersCTRL', function ($scope, $state, routers, $mdDialog) {
        $scope.$state = $state;
        $scope.routers = routers;

        $scope.deleteItem = function (item) {
            console.log(item);
            $scope.item = item;

            $mdDialog.show({
                controller: deleteItemCtrl,
                templateUrl: 'partials/deleteItem.html',
                locals: {
                    item: $scope.item
                }
            })
        };

        function deleteItemCtrl($scope, $mdDialog, item) {
            console.log(item.name);
            $scope.item = item;

            $scope.hide = function () {
                $mdDialog.hide();
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };

            $scope.confirm = function (item) {
                $scope.item = item;
                console.log(item);
                routers.$remove(item).then(function () {
                    $mdDialog.hide();
                });
            };
        }


        $scope.editrouter = function (id) {
            console.log(id);
            $scope.id = id;

            $mdDialog.show({
                controller: editrouterCtrl,
                templateUrl: 'partials/editRouter.html',
                locals: {
                    id: $scope.id,
                    routers: $scope.routers
                }

            });


            function editrouterCtrl($scope, $mdDialog, id, routers) {
                console.log(id);
                $scope.routertoedit = routers.$getRecord(id);
                console.log($scope.routertoedit);
                $scope.backups = ('VSAT Dialup INET').split(' ').map(function (backup) {
                    return {backup: backup};
                });
                $scope.getPic = function () {
                    document.querySelector('.cordova-camera-select').click();
                };

                $scope.hide = function () {
                    $mdDialog.hide();
                };

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };

                $scope.submit = function (obj) {
                    console.log('Updating circuit ', obj);

                    circuits.$save(obj).then(function () {
                        $mdDialog.hide();
                    });

                };
            }
        }

    });