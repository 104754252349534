    .controller('sitesCTRL', function ($scope, $state, sites, $mdDialog, routers, vsats ) {
        // Get a reference to the storage service, which is used to create references in your storage bucket

        $scope.$state = $state;
        $scope.sites = sites;
        console.log("Routers for all sites: " + routers);
        console.log("VSATs for all sites: " + vsats);
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                $scope.user = user;
            } else {
                $state.go('login', {location: 'replace', reload: true});
                console.log('no user');

            }
        });
        var id = sites.$keyAt(0);
        $state.go('sites.detail',({id: id}));



        $scope.addRouter = function (item) {
            console.log("trigger add router " + item.siteID);
            var siteToUpdate = firebase.database().ref('/Sites/' + item.$id);
            var hostname = item.siteID + "-WR01";
            console.log('hostname ' + hostname);
            var siteRouter = firebase.database().ref('/Routers/' + hostname);
         return siteRouter.once('value').then(function(snapshot){
                console.log(snapshot.val());
                if (snapshot.exists()) {
                    siteToUpdate.update({router: hostname});
                } else {
                    siteToUpdate.update({router:''});
                }
            });


        };

        $scope.deleteItem = function (item) {
            console.log(item);
            $scope.item = item;

            $mdDialog.show({
                controller: deleteItemCtrl,
                templateUrl: 'partials/deleteItem.html',
                locals: {
                    item: $scope.item
                }
            })
        };

        function deleteItemCtrl($scope, $mdDialog, item) {
            console.log(item.name);
            $scope.item = item;

            $scope.hide = function () {
                $mdDialog.hide();
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };

            $scope.confirm = function (item) {
                $scope.item = item;
                console.log(item);
                sites.$remove(item).then(function () {
                    $mdDialog.hide();
                });
            };
        }

        $scope.editSite = function ($id) {
            console.log($id);
            $scope.id = $id;
            $mdDialog.show({
                controller: editsiteCtrl,
                templateUrl: 'partials/editSite.html',
                locals: {
                    id: $scope.id
                }

            })
        };


        function editsiteCtrl($scope, $mdDialog, id) {
    console.log("site is " + id);
            var editsite = firebase.database().ref('/Sites/' + id);
    editsite.once('value').then(function (snapshot) {
        var obj = snapshot.val();
        $scope.sitetoedit = obj;
        console.log($scope.sitetoedit);
        $scope.states = ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
            'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
            'WY').split(' ').map(function(state) {
            return {abbrev: state};
        });

    });


    $scope.getPic = function () {
        document.querySelector('.cordova-camera-select').click();
    };

    $scope.hide = function () {
        $mdDialog.hide();
    };

    $scope.cancel = function () {
        $mdDialog.cancel();
    };



    $scope.submit = function () {

        if ($scope.files) {
            var filename = $scope.fileName;
            var file = $scope.files[0];
            console.log(file);
            var sitefile = firebase.storage().ref('/sites/' + id + '/' + filename);

            sitefile.put(file).then(function (snapshot) {
                console.log('uploaded file to storage');
                console.log('url is ' + snapshot.downloadURL);
                var attachment = snapshot.downloadURL;
                updatesite(attachment)

            })

        } else {
            var attachment = $scope.sitetoedit.attachment;
            console.log(attachment);
            updatesite(attachment)
        }

        function updatesite(attachment) {

            console.log($scope.sitetoedit.router);
            var name = $scope.sitetoedit.name;
            var starttime = $scope.starttime;
            var endtime = $scope.endtime;
            if ($scope.sitetoedit.location) {
                var location = $scope.sitetoedit.location;
            } else {
                var location = "";
            }
            if ($scope.sitetoedit.objective) {
                var objective = $scope.sitetoedit.objective;
            } else {
                var objective = "";
            }
            if ($scope.sitetoedit.routers) {
                var routers = $scope.sitetoedit.routers;
            } else {
                var routers = "";
            }
            if ($scope.fileName) {
                var filename = $scope.fileName;
            } else {
                var filename = "";
            }
            if ($scope.sitetoedit.ce) {
                var ce = $scope.sitetoedit.ce;
            } else {
                var ce = "";
            }

            console.log("editing site " + name);
            console.log($scope.sitetoedit);

            return editsite.update({
                name: name,
                starttime: starttime,
                endtime: endtime,
                location: location,
                attachment: attachment,
                filename: filename,
                ce: ce,
                routers: routers,
                objective: objective
            }).then(function () {
                $mdDialog.hide();
            });
        }
        };
    }
});






