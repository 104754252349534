    .controller('circuitsCtrl', function ($scope, $state, circuits, sites, $mdDialog) {
    $scope.$state = $state;
    $scope.circuits = circuits;
    $scope.sites = sites;

    $scope.deleteItem = function (item) {
        console.log(item);
        $scope.item = item;

        $mdDialog.show({
            controller: deleteItemCtrl,
            templateUrl: 'partials/deleteItem.html',
            locals: {
                item: $scope.item
            }
        })
    };

    function deleteItemCtrl($scope, $mdDialog, item) {
        console.log(item.name);
        $scope.item = item;

        $scope.hide = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.confirm = function (item) {
            $scope.item = item;
            console.log(item);
            circuits.$remove(item).then(function () {
                $mdDialog.hide();
            });
        };
    }

    $scope.editCircuit = function ($id) {
        console.log($id);
        $scope.id = $id;

        $mdDialog.show({
            controller: editCircuitCtrl,
            templateUrl: 'partials/editcircuit.html',
            locals: {
                id: $scope.id,
                sites: $scope.sites,
                circuits: $scope.circuits
            },
            onComplete: function () {

            }
        });

        function editCircuitCtrl($scope, $mdDialog, id, circuits) {
            console.log(id);
            $scope.circtoedit = circuits.$getRecord(id);
            console.log($scope.circtoedit);
            $scope.states = ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
                'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
                'WY').split(' ').map(function(state) {
                return {abbrev: state};
            });
            $scope.types = ('Ethernet Unknown').split(' ').map(function(type) {
                return {type: type};
            });

            $scope.getPic = function () {
                document.querySelector('.cordova-camera-select').click();
            };

            $scope.hide = function () {
                $mdDialog.hide();
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };

            $scope.submit = function (obj) {
                console.log('Updating circuit ', obj);

                circuits.$save(obj).then(function () {
                    $mdDialog.hide();
                });

            };
        }
    }

});
